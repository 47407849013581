import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import { GamePackTagBanner } from '../../../app/components/GamePack/GamePackTagBanner';
import {
  ErrorMessage,
  useTriggerManagePinnedGamePacksModal,
} from '../../components/Game/GameCenter';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  MyGamePackBadge,
} from '../../components/Game/GamePack/GamePackCard';
import config from '../../config';
import { useListLoader } from '../../hooks/useListLoader';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { safeWindowReload } from '../../logger/logger';
import { apiService, type GamePacksResponse } from '../../services/api-service';
import { type Tag } from '../../types';
import { type GamePack } from '../../types/game';
import { getGridStyle } from '../../utils/css';
import { GamePackRow } from './Collections';
import { useNumPerRow } from './Context';
import { GamePackCardBottomAccessory } from './GamePackCardBottomAccessory';

function ReorderButton(props: { tag: Tag; onComplete: () => void }) {
  const managePinnedGamePacks = useTriggerManagePinnedGamePacksModal();
  return (
    <button
      type='button'
      className='btn-secondary h-10 w-50'
      onClick={() => {
        managePinnedGamePacks({
          tag: props.tag,
          onComplete: props.onComplete,
        });
      }}
    >
      Reorder Game Packs
    </button>
  );
}

function TagGamePackList(props: {
  tag: Tag;
  personalized: boolean;
  excludeTemplates?: boolean;
  reorderButton?: JSX.Element;
  onGamePackClick: (pack: GamePack, index: number) => void;
}) {
  const {
    tag,
    personalized,
    excludeTemplates,
    reorderButton,
    onGamePackClick,
  } = props;

  const numPerRow = useNumPerRow();
  const paginator = useMemo(
    () =>
      apiService.gamePack.getGamePacksByTagId(
        tag.id,
        personalized,
        36,
        excludeTemplates
      ),
    [personalized, excludeTemplates, tag.id]
  );
  const { items, state, error, handleLoadMore, handleRetry } = useListLoader<
    GamePacksResponse,
    GamePack
  >(paginator);

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between items-center'>
        <p className={`text-2xl font-bold`}>{tag.name}</p>
        {reorderButton}
      </div>

      <div
        className='mt-6 grid gap-y-12 gap-x-3 pt-4'
        style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
      >
        {items.map((p, index) => (
          <GamePackCard
            key={p.id}
            gamePack={p}
            onClick={(pack) => onGamePackClick(pack, index)}
            badges={
              <>
                <MyGamePackBadge gamePack={p} />
                <DefaultGamePackCardBadges gamePack={p} />
              </>
            }
            bottomAccessory={<GamePackCardBottomAccessory pack={p} />}
            styles={{
              size: 'w-full',
            }}
            showVersion={false}
          />
        ))}
      </div>

      {error && (
        <div className='w-full flex items-center justify-center text-white mt-10 text-xl'>
          <ErrorMessage text='Something went wrong' handleRetry={handleRetry} />
        </div>
      )}
      {showEmptyMsg && (
        <div className='w-full flex items-center justify-center text-white mt-10 text-xl'>
          <ErrorMessage
            text={`No Game Packs found, please try again`}
            handleRetry={handleRetry}
          />
        </div>
      )}
      {canLoadMore && (
        <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}
    </div>
  );
}

function OtherExperience(props: {
  tags: Tag[];
  tag: Tag;
  personalized: boolean;
  onViewTag: (tag: Tag) => void;
  onClickGamePack: (
    pack: GamePack,
    index: number,
    tag: Tag,
    tagIndex: number
  ) => void;
}) {
  const showTags = useMemo(() => {
    return shuffle(props.tags.filter((t) => t.id !== props.tag.id));
  }, [props.tag.id, props.tags]);

  return (
    <div className='w-full flex flex-col items-center'>
      <header className='text-3.5xl font-bold font-Montserrat'>
        Other Experiences
      </header>
      <main className='mt-10 w-full flex flex-col gap-15'>
        {showTags.map((tag, tagIndex) => (
          <GamePackRow
            key={tag.id}
            tag={tag}
            onViewAll={() => props.onViewTag(tag)}
            personalized={props.personalized}
            onClickGamePack={(pack, index) =>
              props.onClickGamePack(pack, index, tag, tagIndex)
            }
          />
        ))}
      </main>
    </div>
  );
}

function defaultHideOtherExperiencesChecker(
  tagId: number,
  denylist: number[] = [config.misc.ugcTemplatesTagId]
) {
  return denylist.includes(tagId);
}

export function GamePackTagDetails(props: {
  tags: Tag[];
  tag: Tag;
  personalized: boolean;
  excludeTemplates?: boolean;
  showReorderButton?: boolean;
  onViewTag: (tag: Tag) => void;
  onGamePackClick: (
    pack: GamePack,
    index: number,
    tag: Tag,
    tagIndex: number,
    section: string
  ) => void;
  breadcrumb?: React.ReactNode;
  hideOtherExperiencesChecker?: (tagId: number) => boolean;
}) {
  const {
    tags,
    tag,
    personalized,
    showReorderButton,
    onViewTag,
    onGamePackClick,
    breadcrumb,
  } = props;

  const shouldHideOtherExperiences = useLiveCallback((tagId: number) => {
    const checker =
      props.hideOtherExperiencesChecker || defaultHideOtherExperiencesChecker;
    return checker(tagId);
  });

  return (
    <div
      className='
        w-full bg-modal text-white
        flex flex-col
      '
      key={tag.id}
    >
      <GamePackTagBanner tag={tag} breadcrumb={breadcrumb} />
      <div className='px-10'>
        <TagGamePackList
          tag={tag}
          personalized={personalized}
          excludeTemplates={props.excludeTemplates}
          reorderButton={
            showReorderButton ? (
              <ReorderButton tag={tag} onComplete={() => safeWindowReload()} />
            ) : undefined
          }
          onGamePackClick={(pack, index) =>
            onGamePackClick(pack, index, tag, 0, 'primary')
          }
        />
      </div>

      {!shouldHideOtherExperiences(tag.id) && (
        <div className='mt-20 px-10'>
          <OtherExperience
            tags={tags}
            tag={tag}
            personalized={personalized}
            onViewTag={onViewTag}
            onClickGamePack={(pack, index, tag, tagIndex) =>
              onGamePackClick(pack, index, tag, tagIndex, 'other-experiences')
            }
          />
        </div>
      )}
    </div>
  );
}
